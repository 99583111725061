"use client";
import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { addToCartWish } from "@/context/cart/api";
import Loader from "../loading-spinner";
import { useRouter } from "next/navigation";
import { useCart } from "@/context/cart/CartContext";
import { useAuth } from "../../context/AuthContext";
import getToken from "@/hook/getToken";
import { cn } from "@/utils/cn";
import BASE_URL from "../constants";
const BuyNow = ({ productId, availableForSale, orderQty, className }) => {
  const token = getToken();
  const apiUrl = token ? '/v1/cart/add' : '/v1/cart/add-quick-buy';
  const { isBuyNow, setIsBuyNow, setCart, setBuyItems } = useCart();
  const { setIsSignUpOpen, setIsSignInOpen } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isNotifyClicked, setIsNotifyClicked] = useState(false)
  const handleBuyNow = async (id) => {
    const body = {
      product_id: id,
      order_qty: orderQty,
      buy_now: true,
    };
    const res = await addToCartWish(apiUrl, body).then((res) => res.json());
    if (res.success) {
      const sessionId = res?.session_id;
      if (sessionId) {
        localStorage.setItem('sessionId', sessionId);
      } else {
        localStorage.removeItem('sessionId');
      }

      if (!token && res) {
        setBuyItems(res);
      }
      // const { max_retail_price, list_price, discount } = response;
      // const encodedMaxRetailPrice = encodeURIComponent(max_retail_price);
      // const encodedListPrice = encodeURIComponent(list_price);
      // const encodedDiscount = encodeURIComponent(discount);
      setIsLoading(false);
      if (token) {
        setIsBuyNow((pre) => !pre);
      }
      window.location.href = `/checkout?buynow=true`;
    }
  };
  if (availableForSale) {
    return (
      <button
        onClick={() => handleBuyNow(productId)}
        type="button"
        className={cn(
          "w-full border border-solid border-white text-xs sm:text-sm md:text-base font-sans flex-1 flex justify-center items-center",
          availableForSale ? "bg-gradient-to-r from-[#ff7700] to-[#ff4900] align-top text-white font-semibold hover:scale-105" : "bg-red-400 text-white pointer-events-none",
          " hover:text-white py-2 rounded-lg cursor-pointer transition-all duration-300 transform",
          className
        )}
        disabled={!availableForSale}
      >
        Buy Now {isLoading ? <Loader className="w-4 h-4" /> : null}
      </button>
    );
  }
  const handleNotify = async (productId) => {
    if (!getToken()) {
      return setIsSignInOpen(true)
    }
    const res = await fetch(`${BASE_URL}/v1/notify/me`, {
      method: "POST",
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify({
        product_id: productId
      })
    })
    const data = await res.json()
    if (res.ok) {
      setIsNotifyClicked(true)
      return toast.success(data.message)
    }
    setIsNotifyClicked(true)
    toast.success(data.message)
  }
  return (

    <button
      aria-label="cart-notification"
      onClick={() => handleNotify(productId)}
      className={cn(
        'w-full h-full flex-1 text-xs sm:text-sm md:text-base tracking-tight ',
        'relative inline-flex items-center justify-center px-1 py-2 sm:px-6 sm:py-2',
        'overflow-hidden font-medium text-white transition duration-300 ease-out',
        'rounded-lg shadow-sm group bg-black hover:bg-black', className
      )}
    >
      <span >Notify Me</span>
      <span className="ml-2">
        {
          isNotifyClicked ? (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 ">
              <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
              />
            </svg>
          )
        }
      </span>
    </button>

  )
};

export default BuyNow;
