import { environment } from "@/components/constants";
export const trackAddtoWishlist = (product) => {
    if (environment !== 'prod') return
    if (window.dataLayer) {
        window.dataLayer.push({
            event: "add_to_wishlist",
            ecommerce: {
                items: [
                    {
                        item_id: product.id,
                        item_name: product.productName,
                        index: 0,
                        item_category: product.category,
                        price: product.price,
                        quantity: 1
                    }

                ],
            },
        });
    } else {
        console.warn("GTM dataLayer is not available");
    }
};

export const trackRemovetoWishlist = (product) => {

    if (environment !== 'prod') return
    if (window.dataLayer) {
        window.dataLayer.push({
            event: "remove_from_wishlist",
            ecommerce: {
                items: [
                    {
                        item_id: product.id,
                        item_name: product.productName,
                        index: 0,
                        item_category: product.categorys,
                        price: product.price,
                        quantity: 1
                    }

                ],
            },
        });
    } else {
        console.warn("GTM dataLayer is not available");
    }
};

